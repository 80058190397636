import React, { useEffect, useState, useRef } from 'react';
import LeagueDiv from './components/LeagueDiv';
import './App.css';


const App = () => {
  const [leagueData, setLeagueData] = useState({});
  const [jurisdictionOutcomeIds, setJurisdictionOutcomeIds] = useState({
    NJ: [],
    CO: [],
    IA: [],
    AZ: [],
    VA: []
  });

  const wsRefNJ = useRef(null);
  const wsRefCO = useRef(null);
  const wsRefIA = useRef(null);
  const wsRefAZ = useRef(null);
  const wsRefVA = useRef(null);
  const wsQueueNJ = useRef([]);
  const wsQueueCO = useRef([]);
  const wsQueueIA = useRef([]);
  const wsQueueAZ = useRef([]);
  const wsQueueVA = useRef([]);

  const chunkArray = (array, chunkSize) => {
    const chunks = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      chunks.push(array.slice(i, i + chunkSize));
    }
    return chunks;
  };

  useEffect(() => {
    async function ConnectRefData() {
      const evtSourceNJ = new EventSource(process.env.REACT_APP_EDGE_REFDATA_STREAM_NJ);
      const evtSourceCO = new EventSource(process.env.REACT_APP_EDGE_REFDATA_STREAM_CO);
      const evtSourceIA = new EventSource(process.env.REACT_APP_EDGE_REFDATA_STREAM_IA);
      const evtSourceAZ = new EventSource(process.env.REACT_APP_EDGE_REFDATA_STREAM_AZ);
      const evtSourceVA = new EventSource(process.env.REACT_APP_EDGE_REFDATA_STREAM_VA);

      const newData = {};
      const newJurisdictionOutcomeIds = { NJ: [], CO: [], IA: [], AZ: [], VA: [] };

      const processEvent = (event) => {
        let eventData = JSON.parse(event.data);
        switch (eventData.message_type) {
          case 'LeagueSnapshotEvent':
            Object.values(eventData.leagues).forEach(league => {
              if (!newData[league.leagueId]) {
                newData[league.leagueId] = {
                  leagueId: league.leagueId,
                  shortName: league.shortName,
                  contests: {}
                };
              }
            });
            setLeagueData({ ...newData });
            break;
          case 'ContestSnapshotEvent':
            Object.values(eventData.contests).forEach(contest => {
              const leagueId = contest.leagueId;
              if (!newData[leagueId]) {
                newData[leagueId] = { leagueId, contests: {} };
              }
              newData[leagueId].contests[contest.contestId] = { ...contest, markets: {} };
            });
            setLeagueData({ ...newData });
            break;
          case 'MarketSnapshotEvent':
            Object.values(eventData.markets).forEach(market => {
              const { contestId } = market;
              const league = Object.values(newData).find(league => league.contests[contestId]);
              if (league) {
                const { leagueId } = league;
                newData[leagueId].contests[contestId].markets[market.marketId] = { ...market, outcomes: {} };
              }
            });
            setLeagueData({ ...newData });
            break;
          case 'OutcomeSnapshotEvent':
            Object.values(eventData.outcomes).forEach(outcome => {
              const marketId = outcome.marketId;
              const contest = Object.values(newData).flatMap(league => Object.values(league.contests)).find(contest => contest.markets[marketId]);
              if (contest) {
                const leagueId = contest.leagueId;
                const contestId = contest.contestId;
                if (!newData[leagueId].contests[contestId].markets[marketId].outcomes) {
                  newData[leagueId].contests[contestId].markets[marketId].outcomes = {};
                }
                newData[leagueId].contests[contestId].markets[marketId].outcomes[outcome.outcomeId] = {
                  ...outcome,
                  jurisdiction: outcome.ticker.substring(0, 2),
                  bid: null,
                  offer: null
                };
                newJurisdictionOutcomeIds[outcome.ticker.substring(0, 2)].push(outcome.outcomeId);
              }
            });
            setLeagueData({ ...newData });
            setJurisdictionOutcomeIds({ ...newJurisdictionOutcomeIds });
            break;
          default:
            break;
        }
      };

      evtSourceNJ.onmessage = processEvent;
      evtSourceCO.onmessage = processEvent;
      evtSourceIA.onmessage = processEvent;
      evtSourceAZ.onmessage = processEvent;
      evtSourceVA.onmessage = processEvent;
    }

    ConnectRefData();
  }, []);

  // Function to flush the message queue when the WebSocket connection is open
  const flushQueue = (wsRef, wsQueue) => {
    while (wsQueue.current.length > 0 && wsRef.current.readyState === WebSocket.OPEN) {
      wsRef.current.send(wsQueue.current.shift());
    }
  };

  // Effect to handle WebSocket connections and updates
  useEffect(() => {
    const setupWebSocket = (jurisdiction, outcomeIds, wsRef, wsQueue) => {

      const futureOutcomeIds = outcomeIds.filter(outcomeId => {
        // Find the contest that the outcome belongs to
        for (let league of Object.values(leagueData)) {
          for (let contest of Object.values(league.contests)) {
            if (Object.values(contest.markets).some(market => market.outcomes[outcomeId])) {
              return new Date(contest.scheduledStartTime * 1000) > new Date();
            }
          }
        }
        return false; // In case no matching contest is found
      });

      const outcomeChunks = chunkArray(futureOutcomeIds, 50);

      const connectWebSocket = () => {
        if (wsRef.current && wsRef.current.readyState === WebSocket.OPEN) {
          outcomeChunks.forEach(chunk => {
            const message = JSON.stringify({
              message_type: "MarketDataSubscriptionRequest",
              correlation_id: process.env.REACT_APP_CORRELATION_ID,
              body: { outcome_ids: chunk }
            });
            wsQueue.current.push(message);
            flushQueue(wsRef, wsQueue);
          });
          return;
        }

        wsRef.current = new WebSocket(process.env.REACT_APP_EDGE_MARKET_DATA_SOCKET);

        wsRef.current.onopen = () => {
          console.log(`WebSocket opened in ${jurisdiction}`);
          outcomeChunks.forEach(chunk => {
            const message = JSON.stringify({
              message_type: "MarketDataSubscriptionRequest",
              correlation_id: process.env.REACT_APP_CORRELATION_ID,
              body: { outcome_ids: chunk }
            });
            wsQueue.current.push(message);
            flushQueue(wsRef, wsQueue);
          });
        };

        wsRef.current.onerror = (error) => {
          console.error(`WebSocket error in ${jurisdiction}:`, error);
        };

        wsRef.current.onclose = (e) => {
          console.log(`WebSocket closed in ${jurisdiction}:`, e);
          // Reconnect on close
          setTimeout(connectWebSocket, 5000);
        };

        wsRef.current.onmessage = (message) => {
          const parsedMessage = JSON.parse(message.data);
          // console.log(`Message from ${jurisdiction}:`, parsedMessage);
          if (parsedMessage.message_type === 'MarketDataRefreshEvent' && parsedMessage.body.book) {
            const outcomeId = parsedMessage.body.outcome_id;
            const bid = parsedMessage.body.book.levels.find(level => level.side === 'BID')?.price ?? null;
            const offer = parsedMessage.body.book.levels.find(level => level.side === 'OFFER')?.price ?? null;

            setLeagueData(prevData => {
              const newData = { ...prevData };
              for (let league of Object.values(newData)) {
                for (let contest of Object.values(league.contests)) {
                  for (let market of Object.values(contest.markets)) {
                    if (market.outcomes[outcomeId]) {
                      market.outcomes[outcomeId].bid = bid !== null ? bid / 10000 : null;
                      market.outcomes[outcomeId].offer = offer !== null ? offer / 10000 : null;
                    }
                  }
                }
              }
              return newData;
            });
          }
        };
      };

      connectWebSocket();
    };

    setupWebSocket('NJ', jurisdictionOutcomeIds.NJ, wsRefNJ, wsQueueNJ);
    setupWebSocket('CO', jurisdictionOutcomeIds.CO, wsRefCO, wsQueueCO);
    setupWebSocket('IA', jurisdictionOutcomeIds.IA, wsRefIA, wsQueueIA);
    setupWebSocket('AZ', jurisdictionOutcomeIds.AZ, wsRefAZ, wsQueueAZ);
    setupWebSocket('VA', jurisdictionOutcomeIds.VA, wsRefVA, wsQueueVA);

    return () => {

    };
  }, [jurisdictionOutcomeIds]);

  // Alphabetically sort leagues before rendering
  const sortedLeagues = Object.values(leagueData).sort((a, b) => a.shortName.localeCompare(b.shortName));

  return (
    <div className="App">
      {sortedLeagues.map(league => (
        <LeagueDiv key={league.leagueId} league={league} />
      ))}
    </div>
  );
};

export default App;
