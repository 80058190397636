import React, { useState, useEffect, useMemo } from 'react';
import './styles.css';

const getJurisdictionCounts = (outcomes) => {
  const counts = {
    NJ: { total: 0, nonNull: 0 },
    CO: { total: 0, nonNull: 0 },
    IA: { total: 0, nonNull: 0 },
    AZ: { total: 0, nonNull: 0 },
    VA: { total: 0, nonNull: 0 }
  };

  outcomes.forEach(outcome => {
    const jurisdiction = outcome.jurisdiction;
    if (jurisdiction && counts[jurisdiction]) {
      counts[jurisdiction].total += 1;
      if (outcome.bid !== null || outcome.offer !== null) {
        counts[jurisdiction].nonNull += 1;
      }
    }
  });

  const percentages = {
    NJ: counts.NJ.total > 0 ? Math.round((counts.NJ.nonNull / counts.NJ.total) * 100) : 0,
    CO: counts.CO.total > 0 ? Math.round((counts.CO.nonNull / counts.CO.total) * 100) : 0,
    IA: counts.IA.total > 0 ? Math.round((counts.IA.nonNull / counts.IA.total) * 100) : 0,
    AZ: counts.AZ.total > 0 ? Math.round((counts.AZ.nonNull / counts.AZ.total) * 100) : 0,
    VA: counts.VA.total > 0 ? Math.round((counts.VA.nonNull / counts.VA.total) * 100) : 0
  };
  
  return {
    counts,
    percentages
  };
};

const ContestCard = ({ contest }) => {
  const [trigger, setTrigger] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setTrigger(prev => !prev);
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  const getCardBackgroundColor = () => {
    const allOutcomes = Object.values(contest.markets).flatMap(market => Object.values(market.outcomes));
    const allInactive = allOutcomes.every(outcome => outcome.displayState === 'INACTIVE');
    const allHalted = allOutcomes.every(outcome => outcome.tradingState === 'HALTED');
    const allSettled = allOutcomes.every(outcome => outcome.tradingState === 'SETTLED');

    if (allInactive) return 'lightgray';
    if (allHalted) return 'lightcoral';
    if (allSettled) return 'lightgreen';
    return 'lightyellow';
  };

  const groupMarkets = () => {
    const grouped = {};

    Object.values(contest.markets).forEach(market => {

      const normalizedMarketSymbol = market.symbol.includes(':') && market.symbol.split(':').length > 2
        ? `${market.symbol.split(':')[0]}:${market.symbol.split(':').pop()}`
        : market.symbol;

      if (!grouped[normalizedMarketSymbol]) {
        grouped[normalizedMarketSymbol] = [];
      }
      grouped[normalizedMarketSymbol].push(market);
    });

    return grouped;
  };

  const groupedMarkets = useMemo(groupMarkets, [structuredClone(contest.markets)]);

  const formatDate = (dateString) => {
    const date = new Date(dateString * 1000);
    return date.toLocaleString('en-US', {
      month: '2-digit',
      day: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: false
    });
  };

  const getPercentageStyle = (percentage) => {
    if (percentage === 0) {
      return { background: 'rgba(255, 0, 0, 0.8)' };
    }
    if (percentage > 0 && percentage <= 20) {
      const alpha = 0.8 - (0.8 * (percentage / 20));
      return { background: `rgba(255, 0, 0, ${alpha})` };
    }
    return {};
  };

  const groupedAndSortedMarkets = useMemo(() => {
    const sortedKeys = Object.keys(groupedMarkets).sort((a, b) => {
      const priority = (symbol) => {
        if (symbol.includes('WIN:END')) return 1;
        if (symbol.includes('SPRD:END')) return 2;
        if (symbol.includes('TOT:END')) return 3;
        if (symbol.includes(':END')) return 4;
        return 5;
      };

      const priorityA = priority(a);
      const priorityB = priority(b);

      if (priorityA !== priorityB) {
        return priorityA - priorityB;
      }

      return a.localeCompare(b);
    });

    return sortedKeys.map(key => ({
      normalizedMarketSymbol: key,
      markets: groupedMarkets[key]
    }));
  }, [groupedMarkets]);

  const groupedAndSortedOutcomes = useMemo(() => {
    const groupedOutcomes = {};

    Object.values(contest.markets).forEach(market => {
      Object.values(market.outcomes).forEach(outcome => {
        const normalizedTicker = outcome.ticker.slice(3);
        if (!groupedOutcomes[normalizedTicker]) {
          groupedOutcomes[normalizedTicker] = { NJ: {}, CO: {}, IA: {}, AZ: {},VA: {}, strike: outcome.strike || null };
        }
        groupedOutcomes[normalizedTicker][outcome.jurisdiction] = outcome;
      });
    });

    const sortedKeys = Object.keys(groupedOutcomes).sort((a, b) => {
      const priority = (symbol) => {
        if (symbol.includes('WIN:END')) return 1;
        if (symbol.includes('SPRD:END')) return 2;
        if (symbol.includes('TOT:END')) return 3;
        if (symbol.includes(':END')) return 4;
        return 5;
      };

      const priorityA = priority(a);
      const priorityB = priority(b);

      if (priorityA !== priorityB) {
        return priorityA - priorityB;
      }

      return a.localeCompare(b);
    });

    return sortedKeys.map(key => ({
      normalizedTicker: key,
      jurisdictions: groupedOutcomes[key]
    }));
  }, [structuredClone(contest.markets)]);

  return (
<div className="contest-card" style={{ backgroundColor: getCardBackgroundColor() }}>
  <div className="info-button" onClick={() => setModalOpen(true)}>i</div>
  <h3 className="contest-name">{contest.shortName}</h3>
  <p className="contest-start-time">Start Time: {formatDate(contest.scheduledStartTime)}</p>
  
  <div className="jurisdiction-counts">
    <div className="jurisdiction-header"><strong>Market</strong></div>
    <div className="jurisdiction-header"><strong>NJ</strong></div>
    <div className="jurisdiction-header"><strong>CO</strong></div>
    <div className="jurisdiction-header"><strong>IA</strong></div>
    <div className="jurisdiction-header"><strong>AZ</strong></div>
    <div className="jurisdiction-header"><strong>VA</strong></div>
  </div>
  
  {groupedAndSortedMarkets.map(({ normalizedMarketSymbol, markets }) => {
    const outcomes = markets.flatMap(market => Object.values(market.outcomes));
    const { counts, percentages } = getJurisdictionCounts(outcomes);
    return (
      <div key={normalizedMarketSymbol} className="jurisdiction-counts">
        <div className="jurisdiction-column"><strong>{normalizedMarketSymbol}</strong></div>
        <div className="jurisdiction-column" style={getPercentageStyle(percentages.NJ)}>
          {counts.NJ.total} ({percentages.NJ}%)
        </div>
        <div className="jurisdiction-column" style={getPercentageStyle(percentages.CO)}>
          {counts.CO.total} ({percentages.CO}%)
        </div>
        <div className="jurisdiction-column" style={getPercentageStyle(percentages.IA)}>
          {counts.IA.total} ({percentages.IA}%)
        </div>
        <div className="jurisdiction-column" style={getPercentageStyle(percentages.AZ)}>
          {counts.AZ.total} ({percentages.AZ}%)
        </div>
        <div className="jurisdiction-column" style={getPercentageStyle(percentages.VA)}>
          {counts.VA.total} ({percentages.VA}%)
        </div>
      </div>
    );
  })}
      {modalOpen && (
        <div className="modal">
          <div className="modal-content">
            <span className="close" onClick={() => setModalOpen(false)}>&times;</span>
            <h2>Outcomes for {contest.shortName}</h2>
            <div className="outcome-table">
              <div className="outcome-header">Normalized Ticker</div>
              <div className="outcome-header">NJ Bid/Offer</div>
              <div className="outcome-header">CO Bid/Offer</div>
              <div className="outcome-header">IA Bid/Offer</div>
              <div className="outcome-header">AZ Bid/Offer</div>
              <div className="outcome-header">VA Bid/Offer</div>
              {groupedAndSortedOutcomes.map(({ normalizedTicker, jurisdictions }) => (
                <React.Fragment key={normalizedTicker}>
                  <div className="outcome-row">{normalizedTicker}</div>
                  <div className="outcome-row">
                    {jurisdictions.NJ.bid !== undefined ? `Bid: ${jurisdictions.NJ.bid ?? 'N/A'}, Offer: ${jurisdictions.NJ.offer ?? 'N/A'}` : 'N/A'}
                  </div>
                  <div className="outcome-row">
                    {jurisdictions.CO.bid !== undefined ? `Bid: ${jurisdictions.CO.bid ?? 'N/A'}, Offer: ${jurisdictions.CO.offer ?? 'N/A'}` : 'N/A'}
                  </div>
                  <div className="outcome-row">
                    {jurisdictions.IA.bid !== undefined ? `Bid: ${jurisdictions.IA.bid ?? 'N/A'}, Offer: ${jurisdictions.IA.offer ?? 'N/A'}` : 'N/A'}
                  </div>
                  <div className="outcome-row">
                    {jurisdictions.AZ.bid !== undefined ? `Bid: ${jurisdictions.AZ.bid ?? 'N/A'}, Offer: ${jurisdictions.AZ.offer ?? 'N/A'}` : 'N/A'}
                  </div>
                  <div className="outcome-row">
                    {jurisdictions.VA.bid !== undefined ? `Bid: ${jurisdictions.VA.bid ?? 'N/A'}, Offer: ${jurisdictions.VA.offer ?? 'N/A'}` : 'N/A'}
                  </div>
                </React.Fragment>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ContestCard;