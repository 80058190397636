import React from 'react';
import ContestCard from './ContestCard';
import './styles.css'; // Import CSS for styling

const LeagueDiv = ({ league }) => {
  // Sort contests by scheduledStartTime
  //
  const sortedContests = Object.values(league.contests).sort((a, b) => new Date(a.scheduledStartTime) - new Date(b.scheduledStartTime));

  // Filter contests to include only those with outcomes
  const contestsWithOutcomes = sortedContests.filter(contest => {
    return Object.values(contest.markets).some(market => 
      Object.values(market.outcomes).length > 0
    );
  });

  // If no contests have outcomes, don't render the LeagueDiv
  if (contestsWithOutcomes.length === 0) {
    return null;
  }

  return (
    <div className="league-div">
      <h2>{league.shortName}</h2>
      <div className="contest-cards-container">
        {contestsWithOutcomes.map(contest => {
          const jurisdictionCounts = { NJ: 0, CO: 0, IA: 0 };

          Object.values(contest.markets).forEach(market => {
            Object.values(market.outcomes).forEach(outcome => {
              jurisdictionCounts[outcome.jurisdiction]++;
            });
          });

          return (
            <ContestCard
              key={contest.contestId}
              contest={contest}
              jurisdictionCounts={jurisdictionCounts}
              leagueId={league.leagueId}
            />
          );
        })}
      </div>
    </div>
  );
};

export default LeagueDiv;
